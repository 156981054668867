class GoogleMap {
    constructor() {
        this.container = $('[data-gglmap]');

        if (0 < this.container.length) {
            const place = new google.maps.LatLng(
                '48.689463340503124',
                '2.532371223291857'
            );

            const map = new google.maps.Map(this.container.get(0), {
                center: place,
                zoom: 14,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            });

            const marker = new google.maps.Marker({
                position: place,
                title: 'Lutherie Letourneau',
                animation: google.maps.Animation.DROP,
            });

            marker.setMap(map);
        }
    }
}

export { GoogleMap };
