const $ = require('jquery');
global.$ = global.jQuery = $;

import '@fortawesome/fontawesome-pro/css/all.min.css';

import { YoutubeModal } from './components/YoutubeModal';
import { GoogleMap } from './components/GoogleMap';

$(function () {
    new YoutubeModal();
    new GoogleMap();

    var time = 500;
    var step = 404;

    $('.home-title').hide().removeClass('hidden');
    setTimeout(function () {
        $('.home-title').fadeIn();
    }, 200);

    $('.home-guitar').hide().removeClass('hidden');
    $('.home-guitar').each(function () {
        const $el = $(this);

        setTimeout(function () {
            $el.fadeIn();
        }, time);

        time += step;
    });

    $('.home-cat-title').hide().removeClass('hidden');
    setTimeout(function () {
        $('.home-cat-title').fadeIn();
    }, time);
});
