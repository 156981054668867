import { Modal } from 'bootstrap';

class YoutubeModal {
    constructor($parent) {
        var tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        $('[data-video-modal-trigger]').on('click', function (event) {
            event.preventDefault();

            const $btn = $(this);
            const $modal = $('[data-video-modal]');
            const modal = Modal.getOrCreateInstance($modal.get(0));
            const $loading = $modal.find('[data-modal-loading]');
            const $player = $modal.find('[data-modal-player]');

            modal.show();

            const codeDatas = $btn.data('code').split('#');

            var player = new YT.Player('player', {
                height: '360',
                width: '640',
                videoId: codeDatas[0],
                events: {
                    onReady: function (event) {
                        $loading.hide();
                        $player.fadeIn();

                        event.target.playVideo();

                        if (undefined !== codeDatas[1]) {
                            event.target.seekTo(codeDatas[1]);
                        }
                    },
                },
            });

            $modal.on('hide.bs.modal', function () {
                player.destroy();
                $loading.show();
                $player.hide();
            });
        });
    }
}

export { YoutubeModal };
